<template>
  <p-create v-if="$hasPermission('organizations')" />
  <p-not-found v-else />
</template>

<script>
import NotFound from './../components/NotFound.vue';
import CreateOrganization from './../components/organizations/CreateOrganization.vue';

export default {
  components: {
    'p-create': CreateOrganization,
    'p-not-found': NotFound
  }
};
</script>
